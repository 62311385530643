import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import { useState } from "react";

const colorDict = {
  AEMO_HIGH_IMPACT: "rgba(138, 43, 226, 1)",
  FULL_CURTAILMENT: "rgb(218, 30, 40, 0.5)",
  PARTIAL_CURTAILMENT: "rgb(255, 131, 43, 0.5)",
  LOCAL_PRICE_RISK: "rgb(241, 194, 27, 0.5)",
};

export default function GanttChartLegend() {
  const [anchorElLegend, setAnchorElLegend] = useState(null);
  const [anchorElStatus, setAnchorElStatus] = useState(null);

  const handleClickLegend = (event) => {
    setAnchorElLegend(event.currentTarget);
  };

  const handleCloseLegend = () => {
    setAnchorElLegend(null);
  };

  const handleClickStatus = (event) => {
    setAnchorElStatus(event.currentTarget);
  };

  const handleCloseStatus = () => {
    setAnchorElStatus(null);
  };

  const openLegend = Boolean(anchorElLegend);
  const openStatus = Boolean(anchorElStatus);

  return (
    <>
      <Box display="flex" justifyContent="right">
        {/* Legend Icon and Popup */}
        <Box display="flex" alignItems="center">
          <IconButton
            aria-label="legend"
            aria-describedby={openLegend ? "legend-popover" : undefined}
            onClick={handleClickLegend}
          >
            <InfoIcon />
          </IconButton>
          <Typography variant="body1" fontStyle="italic">
            Legend
          </Typography>
        </Box>
        {/* Outage Status Icon and Popup */}
        <Box display="flex" alignItems="center">
          <IconButton
            aria-label="outage-status"
            aria-describedby={openStatus ? "status-popover" : undefined}
            onClick={handleClickStatus}
          >
            <HelpOutlineIcon />
          </IconButton>
          <Typography variant="body1" fontStyle="italic">
            Status Hint
          </Typography>
        </Box>
      </Box>

      <Popover
        id="legend-popover"
        open={openLegend}
        anchorEl={anchorElLegend}
        onClose={handleCloseLegend}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box p={2} maxWidth={400}>
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            Legend
          </Typography>
          <List>
            {Object.keys(colorDict).map((key) => (
              <ListItem key={key}>
                <Box
                  sx={{
                    width: 20,
                    height: 20,
                    backgroundColor: colorDict[key],
                    marginRight: 1,
                  }}
                />
                <ListItemText primary={key.replace(/_/g, " ")} />
              </ListItem>
            ))}
          </List>
          <Typography variant="body1" gutterBottom>
            Important Note
          </Typography>
          <Typography variant="body2" paragraph>
            An individual asset is colour coded by risk of curtailment, however
            any particular outage may also be classified by AEMO as a High
            Impact Outage (HIO). This will be represented in purple for DUID's
            with risk lower than PARTIAL_CURTAILMENT risk. Otherwise the
            CURTAILMENT risk and colouring scheme will be prioritised. Please
            check TABLE VIEW for complete information.
          </Typography>
        </Box>
      </Popover>

      <Popover
        id="status-popover"
        open={openStatus}
        anchorEl={anchorElStatus}
        onClose={handleCloseStatus}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box p={2} maxWidth={400}>
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            Outage Status Types
          </Typography>
          <Typography variant="body2" paragraph>
            This indicates the likelihood of the outage proceeding. There are
            various planning stages involved with outages. At each stage, the
            system conditions are assessed with respect to the forecast demand
            and the state of the power system. Unplanned events can arise that
            alter the likelihood of a planned outage proceeding. The status of
            an outage may be:
          </Typography>

          <Typography variant="body2" paragraph>
            <strong>SUBMIT</strong> - The outage request has been submitted by
            the TNSP to AEMO for assessment.
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>RESUBMIT</strong> - The outage booking has been updated by
            the TNSP. The outage requires assessment by AEMO.
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>MTLTP</strong> - Medium Term Likely to Proceed. The outage
            has been assessed in the medium term by AEMO and is likely to
            proceed.
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>STLTP</strong> - Short Term Likely to Proceed. The outage
            has been assessed in the short term by AEMO and is likely to
            proceed.
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>PDLTP</strong> - Pre-Dispatch Likely to Proceed. The outage
            has been assessed in pre-dispatch by AEMO and is likely to proceed.
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>PTP</strong> - Permission to Proceed. The outage has been
            given permission by AEMO to begin.
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>PTR</strong> - Permission to Restore. The outage has been
            given permission by AEMO for restoration to begin.
          </Typography>

          <Typography variant="body2" paragraph>
            <strong>UTP</strong> - The Issue could not be resolved at the time
            of assessment and the outage is unlikely to proceed.
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>WD REQ</strong> - Withdraw Request.
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>WDRAWN</strong> - Withdrawn.
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>COMPLETE</strong> - Completed.
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>INFO</strong> - Information.
          </Typography>
        </Box>
      </Popover>
    </>
  );
}
