import {
  Box,
  Chip,
  Grid,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { useCallback } from "react";
import { tokens } from "../../theme";

const riskColors = {
  AEMO_HIGH_IMPACT: "rgb(218, 30, 40, 0.5)", // Deep purple for high impact
  FULL_CURTAILMENT: "rgb(218, 30, 40, 0.5)",
  PARTIAL_CURTAILMENT: "rgb(255, 131, 43, 0.5)",
  LOCAL_PRICE_RISK: "rgb(241, 194, 27, 0.5)",
};

const statusColors = {
  SUBMIT: "rgba(255, 131, 43, 0.5)", // Orange
  MTLTP: "rgba(241, 194, 27, 0.5)", // Yellow
  STLTP: "rgba(241, 194, 27, 0.5)", // Yellow
  PDLTP: "rgba(241, 194, 27, 0.5)", // Yellow
  PTP: "rgba(0, 128, 0, 0.5)", // Green
  PTR: "rgba(0, 0, 255, 0.5)", // Blue
  RESUBMIT: "rgba(255, 131, 43, 0.5)", // Orange
  UTP: "rgba(218, 30, 40, 0.5)", // Red
  "WD REQ": "rgba(218, 30, 40, 0.5)", //Red
  WDRAWN: "rgba(128, 128, 128, 0.5)", //Grey
  COMPLETE: "rgba(128, 128, 128, 0.5)", //Grey
  INFO: "rgba(128, 128, 128, 0.5)", //Grey
};

const NOSHighImpactOutages = ({ outages }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns = [
    {
      field: "filedatetime", // Matches the Django model field
      headerName: "FILE DATETIME",
      minWidth: 60,
      flex: 0.5,
    },
    {
      field: "region", // Matches the Django model field
      headerName: "REGION",
      minWidth: 60,
      flex: 0.5,
    },
    {
      field: "nsp", // Matches the Django model field
      headerName: "NSP",
      minWidth: 60,
      flex: 0.5,
    },
    {
      field: "starttime", // Matches the Django model field
      headerName: "START TIME",
      minWidth: 90,
      flex: 0.75,
    },
    {
      field: "endtime", // Matches the Django model field
      headerName: "END TIME",
      minWidth: 90,
      flex: 0.75,
    },
    {
      field: "networkasset", // Matches the Django model field
      headerName: "NETWORK ASSET",
      minWidth: 100,
      flex: 2,
    },
    {
      field: "impact", // Matches the Django model field
      headerName: "IMPACT",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "recalltimeday", // Matches the Django model field
      headerName: "RECALL TIME DAY",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "recalltimenight", // Matches the Django model field
      headerName: "RECALL TIME NIGHT",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "outagestatuscode",
      headerName: "OUTAGE STATUS CODE",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        const statusLevel = params.value; // Value of the "risk" field
        const chipColor = statusColors[statusLevel] || "default"; // Fallback to "default" if not found
        return (
          <Chip
            label={statusLevel}
            style={{
              backgroundColor: chipColor,
              color: "white",
              fontWeight: "bold",
            }}
            size="small"
          />
        );
      },
    },
  ];

  function DetailPanelContent({ row: rowProp }) {
    return (
      <Stack
        sx={{ py: 1, height: "100%", boxSizing: "border-box" }}
        direction="column"
      >
        <Paper sx={{ flex: 1, mx: "auto", width: "50%", p: 1 }}>
          <Stack direction="column" spacing={0} sx={{ height: 1 }}>
            <Typography
              variant="h4"
              padding={"0px 0px 0px 10px"}
              color={colors.greenAccent[400]}
            >{`${rowProp.networkasset}`}</Typography>
            <Typography variant="h6" align="left" padding={"0px 0px 0px 10px"}>
              {`${rowProp.starttime} - ${rowProp.endtime}`}
            </Typography>
            <Grid container padding={1} rowSpacing={1}>
              <Grid item md={6}>
                <Typography variant="body2" color="textSecondary">
                  Outage Information
                </Typography>
                <Typography variant="body1">
                  {rowProp.region} (region)
                </Typography>
                <Typography variant="body1">{rowProp.nsp} (NSP)</Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant="body2" align="right" color="textSecondary">
                  Outage Details
                </Typography>
                <Typography variant="body1" align="right">
                  {rowProp.outagestatuscode} (status)
                </Typography>

                <Typography variant="body1" align="right">
                  {`${rowProp.recalltimeday} (Recall time day)`}
                </Typography>
                <Typography variant="body1" align="right">
                  {`${rowProp.recalltimenight} (Recall time night)`}
                </Typography>
              </Grid>
              <Grid item md={12}>
                <Typography variant="body2" align="left" color="textSecondary">
                  High Impact Outage
                </Typography>
                <Typography variant="body1" align="left">
                  {rowProp.impact}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Paper>
      </Stack>
    );
  }

  const firstRowAggregation = {
    apply: ({ values }) => (values.length > 0 ? values[0] : null),
    columnTypes: ["string", "number", "date", "dateTime"],
  };

  const getDetailPanelHeight = useCallback(() => 300, []);

  return (
    <Box
      sx={{
        width: "100%",
        "& .name-column--cell": {
          color: colors.grey[100],
        },
        "& .MuiDataGrid-columnHeader": {
          backgroundColor: colors.tableheader,
          borderBottom: "none",
          fontSize: "12px",
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: colors.grey[200],
        },
        "& .MuiDataGrid-cell": {
          fontFamily: "Source Sans Pro, sans-serif", // Font for cell text
          fontSize: "12px",
        },
      }}
    >
      <DataGridPremium
        rows={outages}
        columns={columns}
        pagination
        pageSize={200}
        pageSizeOptions={[100, 200, 300, 400, 500]} // Limit selectable page sizes to 200
        density={"compact"}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={DetailPanelContent}
        rowGroupingColumnMode="multiple"
        aggregationFunctions={{
          firstRow: firstRowAggregation,
        }}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        initialState={{
          pageSize: 200,
          rowGrouping: {
            model: ["outageid"],
          },
          aggregation: {
            model: {
              genconsetid: "firstRow",
              starttime: "firstRow", // Or 'min' if you prefer the earliest time
              endtime: "firstRow", // Or 'max' if you prefer the latest time
              substationid: "firstRow",
              equipmentid: "firstRow",
              equipmenttype: "firstRow",
              outagestatuscode: "firstRow",
              reason: "firstRow",
              resubmitreason: "firstRow",
              lastchanged: "firstRow",
              substationdescription: "firstRow",
              regionid: "firstRow",
              ownerid: "firstRow",
              voltage: "firstRow",
              equipmentdescription: "firstRow",
              highimpact: "firstRow",
              // Include other columns as needed
            },
          },
          groupingExpansion: { "*": true }, // Expand all groups
          columns: {
            columnVisibilityModel: {
              outageid: false,
              substationid: false,
              equipmentid: false,
              description: false,
              lastchanged: false,
              reason: false,
              resubmitreason: false,
              outageimpact: false,
            },
          },
        }}
      />
    </Box>
  );
};

export default NOSHighImpactOutages;
