import { Stack, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { tokens } from "../../../theme";
// Icons for Sidebar
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
// import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
// import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
// import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
// import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import TableRowsOutlinedIcon from "@mui/icons-material/TableRowsOutlined";
import TableViewOutlinedIcon from "@mui/icons-material/TableViewOutlined";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import WindPowerIcon from "@mui/icons-material/WindPower";
// Icons for Sidebar
// import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
// import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
// import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
// import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
const Item = ({
  title,
  section,
  to,
  icon,
  open,
  selected,
  setSelectedMenu,
  setSelectedSection,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <ListItem
      onClick={() => {
        setSelectedMenu(title);
        setSelectedSection(section);
      }}
      key={title}
      disablePadding
      component={Link}
      to={to}
      style={{ color: colors.grey[100] }}
      sx={{
        display: "block",
        mt: 0.5,
        // selected and (selected + hover) states
        "&& .Mui-selected, && .Mui-selected:hover": {
          bgcolor: colors.greenAccent[600],
          "&, & .MuiListItemIcon-root": {
            color: colors.grey[100],
          },
        },
        // hover states
        "& .MuiListItemButton-root:hover": {
          bgcolor: colors.greenAccent[300],
          "&, & .MuiListItemIcon-root": {
            color: colors.grey[800],
          },
        },
      }}
    >
      <ListItemButton
        selected={selected === title}
        sx={{
          minHeight: 30,
          justifyContent: open ? "initial" : "initial",
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            ml: 0.5,
            justifyContent: open ? "initial" : "center",
          }}
        >
          {icon}
        </ListItemIcon>

        <ListItemText
          primary={title}
          variant="h3"
          color={colors.grey[100]}
          sx={{ opacity: open ? 1 : 0 }}
        />
        <Link to={to} />
      </ListItemButton>
    </ListItem>
  );
};

// import BoltIcon from "@mui/icons-material/Bolt";
const SidebarMenu = ({ open, menu, setMenu, setSectionMenu }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Stack
      direction="column"
      sx={{ flexGrow: 0, p: 0, justifyContent: "space-between" }}
    >
      <Fragment>
        <Divider />
        {open ? (
          <Typography
            variant="subtitle3"
            color={colors.grey[100]}
            sx={{ m: "5px 15px 5px 15px" }}
          >
            NEM Transmission Map
          </Typography>
        ) : (
          <Typography
            variant="subtitle3"
            color={colors.grey[100]}
            sx={{ m: "5px 16px 5px 16px" }}
          >
            MAP
          </Typography>
        )}
        <List>
          <Item
            title="Map v0.1"
            section="NEM Transmission Map"
            to="/map"
            icon={<MapOutlinedIcon />}
            open={open}
            selected={menu}
            setSelectedMenu={setMenu}
            setSelectedSection={setSectionMenu}
          />
        </List>
        <Divider />
        {open ? (
          <Typography
            variant="subtitle3"
            color={colors.grey[100]}
            sx={{ m: "15px 15px 15px 15px" }}
          >
            DUID Specific
          </Typography>
        ) : (
          <Typography
            variant="subtitle3"
            color={colors.grey[100]}
            sx={{ m: "5px 15px 5px 15px" }}
          >
            DUID
          </Typography>
        )}
        <List>
          <Item
            title="Dashboard"
            section="DUID Specific"
            to="/dashboard"
            icon={<WindPowerIcon />}
            open={open}
            selected={menu}
            setSelectedMenu={setMenu}
            setSelectedSection={setSectionMenu}
          />

          <Item
            title="Constraints"
            section="DUID Specific"
            to="/constraint/list"
            icon={<ListAltOutlinedIcon />}
            open={open}
            selected={menu}
            setSelectedMenu={setMenu}
            setSelectedSection={setSectionMenu}
          />
          <Item
            title="Constraint Sets"
            section="DUID Specific"
            to="/constraint_set/list"
            icon={<LibraryBooksOutlinedIcon />}
            open={open}
            selected={menu}
            setSelectedMenu={setMenu}
            setSelectedSection={setSectionMenu}
          />
        </List>
        <Divider />

        <Divider />
        {open ? (
          <Typography
            variant="subtitle3"
            color={colors.grey[100]}
            sx={{ m: "5px 15px 5px 15px" }}
          >
            Portfolio Assets
          </Typography>
        ) : (
          <Typography
            variant="subtitle3"
            color={colors.grey[100]}
            sx={{ m: "5px 6px 5px 6px" }}
          >
            ASSETS
          </Typography>
        )}
        <List>
          <Item
            title="Portfolio"
            section="Portfolio Assets"
            to="/portfolio"
            icon={<AccountBalanceIcon />}
            open={open}
            selected={menu}
            setSelectedMenu={setMenu}
            setSelectedSection={setSectionMenu}
          />
        </List>
        <Divider />

        <Divider />
        {open ? (
          <Typography
            variant="subtitle3"
            color={colors.grey[100]}
            sx={{ m: "5px 15px 5px 15px" }}
          >
            NOS Tables
          </Typography>
        ) : (
          <Typography
            variant="subtitle3"
            color={colors.grey[100]}
            sx={{ m: "5px 15px 5px 15px" }}
          >
            NOS
          </Typography>
        )}

        <List>
          <Item
            title="High Impact Outages"
            section="NOS Tables"
            to="/network/high-impact-outages"
            icon={<WarningOutlinedIcon />}
            open={open}
            selected={menu}
            setSelectedMenu={setMenu}
            setSelectedSection={setSectionMenu}
          />
          <Item
            title="Constraints Only"
            section="NOS Tables"
            to="/network/constraintsets"
            icon={<TableViewOutlinedIcon />}
            open={open}
            selected={menu}
            setSelectedMenu={setMenu}
            setSelectedSection={setSectionMenu}
          />

          <Item
            title="All Outages"
            section="NOS Tables"
            to="/network/all-outages"
            icon={<TableRowsOutlinedIcon />}
            open={open}
            selected={menu}
            setSelectedMenu={setMenu}
            setSelectedSection={setSectionMenu}
          />
        </List>
        <Divider />
        {/* <MenuContent /> */}
        {/* <CardAlert /> */}
      </Fragment>
    </Stack>
  );
};

export default SidebarMenu;
