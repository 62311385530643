import {
  Box,
  Chip,
  Grid,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { useCallback } from "react";
import { tokens } from "../../theme";

const riskColors = {
  AEMO_HIGH_IMPACT: "rgb(218, 30, 40, 0.5)", // rgba(0, 188, 212, 0.5)", // Deep purple for high impact
  FULL_CURTAILMENT: "rgb(218, 30, 40, 0.5)",
  PARTIAL_CURTAILMENT: "rgb(255, 131, 43, 0.5)",
  LOCAL_PRICE_RISK: "rgb(241, 194, 27, 0.5)",
};

const AssetConstraintTable = ({ constraints }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns = [
    {
      field: "outageid",
      headerName: "OUTAGE ID",
      flex: 0.5,
      hide: true,
    },
    {
      field: "genconsetid",
      headerName: "CONSTRAINT SET",
      flex: 1,
    },
    { field: "genconid", headerName: "CONSTRAINT", flex: 1.5 },
    {
      field: "description",
      headerName: "DESCRIPTION",
      flex: 0.75,
      hide: true,
    },
    { field: "duid", headerName: "DUID", flex: 0.75 },
    { field: "starttime", headerName: "START TIME", flex: 1 },
    { field: "endtime", headerName: "END TIME", flex: 1 },
    { field: "equipmentid", headerName: "EQUIPMENT ID", flex: 0.75 },
    { field: "equipmenttype", headerName: "TYPE", flex: 0.5 },
    {
      field: "substationid",
      headerName: "SUBSTATION ID",
      flex: 1,
    },
    // {
    //   field: "substationdescription",
    //   headerName: "SUBSTATION DESCRIPTION",
    //   flex: 1,
    // },
    // {
    //   field: "regionid",
    //   headerName: "REGION",
    //   flex: 0.5,
    // },
    // {
    //   field: "ownerid",
    //   headerName: "OWNER",
    //   flex: 0.5,
    // },
    // {
    //   field: "voltage",
    //   headerName: "VOLTAGE",
    //   flex: 0.5,
    // },
    // {
    //   field: "equipmentdescription",
    //   headerName: "EQUIPMENT DESCRIPTION",
    //   flex: 1,
    // },
    {
      field: "outagestatuscode",
      headerName: "STATUS",
      flex: 0.5,
    },
    {
      field: "risk",
      headerName: "RISK",
      flex: 1,
      renderCell: (params) => {
        const riskLevel = params.value; // Value of the "risk" field
        const chipColor = riskColors[riskLevel] || "default"; // Fallback to "default" if not found
        return (
          <Chip
            label={riskLevel}
            style={{
              backgroundColor: chipColor,
              color: "white",
              fontWeight: "bold",
            }}
            size="small"
          />
        );
      },
    },
    { field: "bidtype", headerName: "BIDTYPE", flex: 0.75 },
    { field: "limittype", headerName: "LIMIT TYPE", flex: 1 },
    {
      field: "lastchanged",
      headerName: "LAST CHANGED",
      flex: 1,
      hide: true,
    },
    {
      field: "highimpact",
      headerName: "AEMO HIGH IMPACT",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        if (params.value === "Yes") {
          const chipColor = riskColors["AEMO_HIGH_IMPACT"] || "default"; // Fallback to "default" if not found
          return (
            <Chip
              label={"AEMO"}
              style={{
                backgroundColor: chipColor,
                color: "white",
                fontWeight: "bold",
              }}
              size="small"
            />
          );
        }
      },
    },
    {
      field: "outageimpact",
      headerName: "IMPACT",
      minWidth: 120,
      flex: 1,
      hide: true,
    },
  ];

  function DetailPanelContent({ row: rowProp }) {
    return (
      <Stack
        sx={{ py: 1, height: "100%", boxSizing: "border-box" }}
        direction="column"
      >
        <Paper sx={{ flex: 1, mx: "auto", width: "50%", p: 1 }}>
          <Stack direction="column" spacing={0} sx={{ height: 1 }}>
            <Typography
              variant="h4"
              padding={"0px 0px 0px 10px"}
              color={colors.greenAccent[400]}
            >{`${rowProp.duid}`}</Typography>
            <Grid container padding={1} rowSpacing={1}>
              <Grid item md={6}>
                <Typography variant="body2" color="textSecondary">
                  Outage Information
                </Typography>
                <Typography variant="body1">
                  {rowProp.outageid} (outage id)
                </Typography>
                <Typography variant="body1">
                  {rowProp.genconsetid} (constraint set)
                </Typography>
                <Typography variant="body1">
                  {rowProp.genconid} (constraint)
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant="body2" align="right" color="textSecondary">
                  Constraint Details
                </Typography>
                <Typography variant="body1" align="right">
                  {rowProp.outagestatuscode} (status)
                </Typography>
                <Typography variant="body1" align="right">
                  {rowProp.limittype} (limit type)
                </Typography>
                <Typography variant="body1" align="right">
                  {`${rowProp.starttime} - ${rowProp.endtime}`}
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant="body2" align="left" color="textSecondary">
                  Description
                </Typography>
                <Typography variant="body1" align="left">
                  {rowProp.description}
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant="body2" align="right" color="textSecondary">
                  Equipment Details
                </Typography>
                <Typography variant="body1" align="right">
                  {`${rowProp.equipmenttype} (type)`}
                </Typography>
                <Typography variant="body1" align="right">
                  {`${rowProp.equipmentid} (equipment)`}
                </Typography>
                <Typography variant="body1" align="right">
                  {`${rowProp.substationid} (substation)`}
                </Typography>
              </Grid>
              <Grid item md={12}>
                <Typography variant="body2" align="left" color="textSecondary">
                  High Impact Outage
                </Typography>
                <Typography variant="body1" align="left">
                  {rowProp.outageimpact}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Paper>
      </Stack>
    );
  }

  const firstRowAggregation = {
    apply: ({ values }) => (values.length > 0 ? values[0] : null),
    columnTypes: ["string", "number", "date", "dateTime"],
  };

  const getDetailPanelHeight = useCallback(() => 300, []);

  return (
    <Box
      sx={{
        width: "100%",
        "& .name-column--cell": {
          color: colors.grey[100],
        },
        "& .MuiDataGrid-columnHeader": {
          backgroundColor: colors.tableheader,
          borderBottom: "none",
          fontSize: "12px",
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: colors.grey[200],
        },
        "& .MuiDataGrid-cell": {
          fontFamily: "Source Sans Pro, sans-serif", // Font for cell text
          fontSize: "12px",
        },
      }}
    >
      <DataGridPremium
        rows={constraints}
        columns={columns}
        pageSize={200}
        pageSizeOptions={[100, 200, 300, 400, 500]} // Limit selectable page sizes to 200
        pagination
        density={"compact"}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={DetailPanelContent}
        rowGroupingColumnMode="multiple"
        aggregationFunctions={{
          firstRow: firstRowAggregation,
        }}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        initialState={{
          pageSize: 200,
          rowGrouping: {
            model: ["outageid"],
          },
          aggregation: {
            model: {
              genconsetid: "firstRow",
              genconid: "firstRow",
              duid: "firstRow",
              starttime: "firstRow",
              endtime: "firstRow",
              equipmentid: "firstRow",
              equipmenttype: "firstRow",
              substationid: "firstRow",
              outagestatuscode: "firstRow",
              risk: "firstRow",
              bidtype: "firstRow",
              limittype: "firstRow",
              substationdescription: "firstRow",
              regionid: "firstRow",
              ownerid: "firstRow",
              voltage: "firstRow",
              equipmentdescription: "firstRow",
              highimpact: "firstRow",
              // Add other columns as needed
            },
          },
          groupingExpansion: { "*": true }, // Expand all groups
          columns: {
            columnVisibilityModel: {
              outageid: false,
              equipmentid: false,
              substationid: false,
              description: false,
              lastchanged: false,
              outageimpact: false,
            },
          },
        }}
      />
    </Box>
  );
};

export default AssetConstraintTable;
