import { Box, Chip, useTheme } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { tokens } from "../../theme";

const riskColors = {
  AEMO_HIGH_IMPACT: "rgb(218, 30, 40, 0.5)", // Deep purple for high impact
  FULL_CURTAILMENT: "rgb(218, 30, 40, 0.5)",
  PARTIAL_CURTAILMENT: "rgb(255, 131, 43, 0.5)",
  LOCAL_PRICE_RISK: "rgb(241, 194, 27, 0.5)",
};

const statusColors = {
  SUBMIT: "rgba(255, 131, 43, 0.5)", // Orange
  MTLTP: "rgba(241, 194, 27, 0.5)", // Yellow
  STLTP: "rgba(241, 194, 27, 0.5)", // Yellow
  PDLTP: "rgba(241, 194, 27, 0.5)", // Yellow
  PTP: "rgba(0, 128, 0, 0.5)", // Green
  PTR: "rgba(0, 0, 255, 0.5)", // Blue
  RESUBMIT: "rgba(255, 131, 43, 0.5)", // Orange
  UTP: "rgba(218, 30, 40, 0.5)", // Red
  "WD REQ": "rgba(218, 30, 40, 0.5)", //Red
  WDRAWN: "rgba(128, 128, 128, 0.5)", //Grey
  COMPLETE: "rgba(128, 128, 128, 0.5)", //Grey
  INFO: "rgba(128, 128, 128, 0.5)", //Grey
};

const NOSConstraintSetTable = ({ constraints }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns = [
    { field: "outageid", headerName: "OUTAGE ID", minWidth: 75, flex: 0.75 },
    {
      field: "genconsetid",
      headerName: "CONSTRAINT SET",
      minWidth: 150,
      flex: 1,
    },
    { field: "starttime", headerName: "START TIME", minWidth: 120, flex: 1 },
    { field: "endtime", headerName: "END TIME", minWidth: 120, flex: 1 },
    {
      field: "substationid",
      headerName: "SUBSTATION ID",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "substationdescription",
      headerName: "SUBSTATION DESCRIPTION",
      flex: 1,
    },
    {
      field: "regionid",
      headerName: "REGION",
      flex: 0.5,
    },
    {
      field: "ownerid",
      headerName: "OWNER",
      flex: 0.5,
    },
    {
      field: "voltage",
      headerName: "VOLTAGE",
      flex: 0.5,
    },
    {
      field: "equipmentdescription",
      headerName: "EQUIPMENT DESCRIPTION",
      flex: 1,
    },
    {
      field: "equipmentid",
      headerName: "EQUIPMENT ID",
      minWidth: 75,
      flex: 0.75,
    },
    { field: "equipmenttype", headerName: "TYPE", minWidth: 75, flex: 0.5 },
    {
      field: "outagestatuscode",
      headerName: "OUTAGE STATUS CODE",
      minWidth: 75,
      flex: 0.5,
      renderCell: (params) => {
        const statusLevel = params.value; // Value of the "risk" field
        const chipColor = statusColors[statusLevel] || "default"; // Fallback to "default" if not found
        return (
          <Chip
            label={statusLevel}
            style={{
              backgroundColor: chipColor,
              color: "white",
              fontWeight: "bold",
            }}
            size="small"
          />
        );
      },
    },
    { field: "reason", headerName: "REASON", minWidth: 150, flex: 1 },
    {
      field: "lastchanged",
      headerName: "LAST CHANGED",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "highimpact",
      headerName: "AEMO HIGH IMPACT",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        if (params.value === "Yes") {
          const chipColor = riskColors["AEMO_HIGH_IMPACT"] || "default"; // Fallback to "default" if not found
          return (
            <Chip
              label={"AEMO"}
              style={{
                backgroundColor: chipColor,
                color: "white",
                fontWeight: "bold",
              }}
              size="small"
            />
          );
        }
      },
    },
    {
      field: "outageimpact",
      headerName: "IMPACT",
      minWidth: 120,
      flex: 1,
      hide: true,
    },
  ];

  const firstRowAggregation = {
    apply: ({ values }) => (values.length > 0 ? values[0] : null),
    columnTypes: ["string", "number", "date", "dateTime"],
  };

  return (
    <Box
      sx={{
        width: "100%",
        "& .name-column--cell": {
          color: colors.grey[100],
        },
        "& .MuiDataGrid-columnHeader": {
          backgroundColor: colors.tableheader,
          borderBottom: "none",
          fontSize: "12px",
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: colors.grey[200],
        },
        "& .MuiDataGrid-cell": {
          fontFamily: "Source Sans Pro, sans-serif", // Font for cell text
          fontSize: "12px",
        },
      }}
    >
      <DataGridPremium
        rows={constraints}
        columns={columns}
        pageSize={200}
        pageSizeOptions={[100, 200, 300, 400, 500]} // Limit selectable page sizes to 200
        pagination
        density={"compact"}
        rowGroupingColumnMode="multiple"
        aggregationFunctions={{
          firstRow: firstRowAggregation,
        }}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        initialState={{
          pageSize: 200,
          rowGrouping: {
            model: ["outageid"],
          },
          aggregation: {
            model: {
              genconsetid: "firstRow",
              starttime: "firstRow", // Or 'min' if you prefer the earliest time
              endtime: "firstRow", // Or 'max' if you prefer the latest time
              substationid: "firstRow",
              equipmentid: "firstRow",
              equipmenttype: "firstRow",
              outagestatuscode: "firstRow",
              reason: "firstRow",
              lastchanged: "firstRow",
              substationdescription: "firstRow",
              regionid: "firstRow",
              ownerid: "firstRow",
              voltage: "firstRow",
              equipmentdescription: "firstRow",
              highimpact: "firstRow",
              // Include other columns as needed
            },
          },
          groupingExpansion: { "*": true }, // Expand all groups
          columns: {
            columnVisibilityModel: {
              outageid: false,
              substationid: false,
              equipmentid: false,
              description: false,
              lastchanged: false,
              outageimpact: false,
            },
          },
        }}
      />
    </Box>
  );
};

export default NOSConstraintSetTable;
