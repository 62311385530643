import {
  Box,
  Checkbox,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { GanttChart } from "../../components/gantt-chart/GanttChart";
import GanttChartLegendNOS from "../../components/gantt-chart/GanttChartLegendNOS";
import Refresh from "../../components/Refresh";
import Header from "../../components/section-headers/Header";
import SectionHeader from "../../components/section-headers/SectionHeader";
import NOSConstraintSetTable from "../../components/tables/NOSConstraintSetTable";
import AuthContext from "../../context/AuthContext";
import DataContext from "../../context/DataContext";
import { tokens } from "../../theme";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const NetworkOutageSet = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let { authTokens } = useContext(AuthContext);
  let {
    refresh,
    constraintSets,
    getNetworkOutageConstraintSet,
    filedatetime,
    highImpactOutages,
    matchHighImpactOutages,
    getHighImpactOutages,
  } = useContext(DataContext);
  const [value, setValue] = useState(0);
  const [mixedConstraints, setMixedConstraints] = useState(0);
  const [genconids, setGenconids] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([
    "SUBMIT",
    "RESUBMIT",
    "UTP",
    "MTLTP",
    "STLTP",
    "PDLTP",
    "PTP",
    "PTR",
  ]);
  const [filteredoutages, setFilteredconstraints] = useState([]);

  useEffect(() => {
    if (mixedConstraints) {
      const filteredConstraints = mixedConstraints.filter((constraint) =>
        selectedStatuses.includes(constraint.outagestatuscode)
      );
      setFilteredconstraints(filteredConstraints);

      const genconsetidSelection = [
        ...new Set(filteredConstraints.map((constr) => constr.genconsetid)),
      ];
      setGenconids(genconsetidSelection);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mixedConstraints, selectedStatuses]);

  const callHighImpactOutages = async () => {
    await getHighImpactOutages(authTokens);

    // setConstraints(_constraintSet);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (constraintSets && highImpactOutages) {
      const mixedData = matchHighImpactOutages(
        constraintSets,
        highImpactOutages
      );
      setMixedConstraints(mixedData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [constraintSets, highImpactOutages]);

  const callConstraints = async () => {
    getNetworkOutageConstraintSet(authTokens, filedatetime);

    // setConstraints(_constraintSet);
  };

  const columns = [
    { field: "outageid", headerName: "OUTAGE ID", minWidth: 75, flex: 0.75 },
    {
      field: "genconsetid",
      headerName: "CONSTRAINT SET",
      minWidth: 150,
      flex: 1,
    },
    { field: "starttime", headerName: "START TIME", minWidth: 120, flex: 1 },
    { field: "endtime", headerName: "END TIME", minWidth: 120, flex: 1 },
    {
      field: "substationid",
      headerName: "SUBSTATION ID",
      minWidth: 100,
      flex: 1,
    },
    { field: "equipmentid", headerName: "ID", minWidth: 75, flex: 0.75 },
    { field: "equipmenttype", headerName: "TYPE", minWidth: 75, flex: 0.5 },
    {
      field: "outagestatuscode",
      headerName: "STATUS",
      minWidth: 75,
      flex: 0.5,
    },
    { field: "reason", headerName: "REASON", minWidth: 150, flex: 1 },
    {
      field: "lastchanged",
      headerName: "LAST CHANGED",
      minWidth: 120,
      flex: 1,
    },
  ];

  useEffect(() => {
    callHighImpactOutages(authTokens);
  }, []);

  useEffect(() => {
    if (constraintSets && highImpactOutages) {
      const mixedData = matchHighImpactOutages(
        constraintSets,
        highImpactOutages
      );
      setMixedConstraints(mixedData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [constraintSets, highImpactOutages]);

  useEffect(() => {
    if (constraintSets.length === 0) {
      callConstraints();
    } else {
      // nothing
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box m="5px">
      <Header
        title="Complete Constraint Set"
        subtitle="List of Network Outage Constraints Only in Latest NOS submission"
      />

      <Stack direction="row" spacing={2} mb={2}>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center", mb: 2 }}>
          {/* Status Filter */}
          <Box
            display="flex"
            alignItems="center"
            size="small"
            sx={{
              backgroundColor: colors.primary[400],
              borderRadius: "3px",
              padding: "0 0px ",
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              p="0px 10px 0px 10px"
              sx={{
                color: colors.grey[100],
              }}
            >
              Filter by Status
            </Typography>
            <Select
              multiple
              value={selectedStatuses}
              onChange={(e) => setSelectedStatuses(e.target.value)}
              renderValue={(selected) => selected.join(", ")}
              size="small"
            >
              {[
                "SUBMIT",
                "UTP",
                "MTLTP",
                "STLTP",
                "PDLTP",
                "PTP",
                "PTR",
                "RESUBMIT",
                "WD REQ",
                "WDRAWN",
                "INFO",
                "COMPLETE",
              ].map((status) => (
                <MenuItem key={status} value={status}>
                  <Checkbox
                    checked={selectedStatuses.indexOf(status) > -1}
                    style={{
                      color:
                        selectedStatuses.indexOf(status) > -1
                          ? colors.greenAccent[400]
                          : undefined,
                    }}
                  />
                  {status}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </Stack>

      {(() => {
        if ((filteredoutages.length > 0) & (refresh === false)) {
          return (
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs example"
                >
                  <Tab label="Gantt Chart" {...a11yProps(0)} />
                  <Tab label="Table View" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                {/* GANTT CHART VIEW */}
                <SectionHeader
                  title="Gantt Chart"
                  subtitle={`Complete List of Constraint Sets in Latest Network Outage Schedule Update`}
                />
                <GanttChartLegendNOS />
                <GanttChart
                  genconsetidSelection={genconids}
                  constraints={filteredoutages}
                  columns={columns}
                />
              </CustomTabPanel>

              <CustomTabPanel value={value} index={1}>
                {/* TABLE VIEW */}
                <SectionHeader
                  title="Table View"
                  subtitle={`Complete List of Constraint Sets in Latest Network Outage Schedule Update`}
                />

                <NOSConstraintSetTable constraints={filteredoutages} />
              </CustomTabPanel>
            </Box>
          );
        } else if (refresh === true) {
          return <Refresh refresh={refresh} />;
        } else {
          return null;
        }
      })()}
    </Box>
  );
};

export default NetworkOutageSet;
