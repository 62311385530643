import {
  Box,
  Checkbox,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { GanttChart } from "../../components/gantt-chart/GanttChart";
import GanttChartLegend from "../../components/gantt-chart/GanttChartLegend";
import Refresh from "../../components/Refresh";
import Header from "../../components/section-headers/Header";
import SectionHeader from "../../components/section-headers/SectionHeader";
import AssetConstraintTable from "../../components/tables/AssetConstraintTable";
import AuthContext from "../../context/AuthContext";
import DataContext from "../../context/DataContext";
import { tokens } from "../../theme";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const riskLevels = {
  LOCAL_PRICE_RISK: 0,
  PARTIAL_CURTAILMENT: 1,
  FULL_CURTAILMENT: 2,
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  if (index === 0) {
    // window.location.replace("#gantt-chart");
  }
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let { authTokens } = useContext(AuthContext);
  let {
    duid,
    refresh,
    constraints,
    loadDuidConstraintData,
    highImpactOutages,
    matchHighImpactOutages,
    getHighImpactOutages,
  } = useContext(DataContext);
  const [value, setValue] = useState(0);
  const [mixedConstraints, setMixedConstraints] = useState(0);
  const [selectedRisk, setSelectedRisk] = useState("LOCAL_PRICE_RISK");
  const [selectedStatuses, setSelectedStatuses] = useState([
    "SUBMIT",
    "RESUBMIT",
    "UTP",
    "MTLTP",
    "STLTP",
    "PDLTP",
    "PTP",
    "PTR",
  ]);
  const [genconids, setGenconids] = useState([]);
  const [filteredconstraints, setFilteredconstraints] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const callDuidConstraintData = async () => {
    await loadDuidConstraintData(authTokens, duid);

    // setConstraints(_constraintSet);
  };

  const callHighImpactOutages = async () => {
    await getHighImpactOutages(authTokens);

    // setConstraints(_constraintSet);
  };

  useEffect(() => {
    // on load
    callDuidConstraintData();
  }, []);

  useEffect(() => {
    callHighImpactOutages(authTokens);
  }, []);

  useEffect(() => {
    if (constraints && highImpactOutages) {
      const mixedData = matchHighImpactOutages(constraints, highImpactOutages);
      setMixedConstraints(mixedData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [constraints, highImpactOutages]);

  useEffect(() => {
    if (mixedConstraints) {
      const filteredConstraints = mixedConstraints.filter(
        (constraint) =>
          riskLevels[constraint.risk] >= riskLevels[selectedRisk] &&
          (selectedStatuses.length === 0 ||
            selectedStatuses.includes(constraint.outagestatuscode))
      );

      setFilteredconstraints(filteredConstraints);

      const genconsetidSelection = [
        ...new Set(filteredConstraints.map((constr) => constr.genconsetid)),
      ];
      setGenconids(genconsetidSelection);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mixedConstraints, selectedRisk, selectedStatuses]);

  useEffect(() => {
    // Log the initial pageview
    ReactGA.send({ hitType: "dashboard", page: location.pathname });
  }, [location]);

  return (
    <Box m="5px">
      <Header
        title="Asset Dashboard"
        subtitle="Use the search bar above to get a list of Network Outage Constraints that may impact a specific DUID, Interconnector or Region"
      />
      <Stack direction="row" spacing={2} mb={2}>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center", mb: 2 }}>
          {/* Risk Filter */}
          <Box
            display="flex"
            alignItems="center"
            size="small"
            sx={{
              backgroundColor: colors.primary[400],
              borderRadius: "3px",
              padding: "0 0px ",
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              p="0px 10px 0px 10px"
              sx={{
                color: colors.grey[100],
              }}
            >
              Filter by Risk
            </Typography>
            <Select
              value={selectedRisk}
              onChange={(e) => setSelectedRisk(e.target.value)}
              size="small"
            >
              <MenuItem value="" disabled>
                Select Risk {">="} Level
              </MenuItem>
              {Object.keys(riskLevels).map((risk) => (
                <MenuItem key={risk} value={risk}>
                  {risk}
                </MenuItem>
              ))}
            </Select>
          </Box>

          {/* Status Filter */}
          <Box
            display="flex"
            alignItems="center"
            size="small"
            sx={{
              backgroundColor: colors.primary[400],
              borderRadius: "3px",
              padding: "0 0px ",
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              p="0px 10px 0px 10px"
              sx={{
                color: colors.grey[100],
              }}
            >
              Filter by Status
            </Typography>
            <Select
              multiple
              value={selectedStatuses}
              onChange={(e) => setSelectedStatuses(e.target.value)}
              renderValue={(selected) => selected.join(", ")}
              size="small"
            >
              {[
                "SUBMIT",
                "UTP",
                "MTLTP",
                "STLTP",
                "PDLTP",
                "PTP",
                "PTR",
                "RESUBMIT",
                "WD REQ",
                "WDRAWN",
                "INFO",
                "COMPLETE",
              ].map((status) => (
                <MenuItem key={status} value={status}>
                  <Checkbox
                    checked={selectedStatuses.indexOf(status) > -1}
                    style={{
                      color:
                        selectedStatuses.indexOf(status) > -1
                          ? colors.greenAccent[400]
                          : undefined,
                    }}
                  />
                  {status}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </Stack>

      {(() => {
        if ((filteredconstraints.length > 0) & (refresh === false)) {
          return (
            <Box m="5px">
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                  >
                    <Tab label="Gantt Chart" {...a11yProps(0)} />
                    {/* <Tab label="Tree View" {...a11yProps(1)} /> */}
                    <Tab label="Table View" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  {/* GANTT CHART VIEW */}

                  <SectionHeader
                    title="Gantt Chart"
                    subtitle={`View the Constraint Sets that will impact ${duid}. Click on Tree or Table View for more details.`}
                  />
                  <Box id="gantt-chart">
                    <GanttChartLegend />
                    <GanttChart
                      genconsetidSelection={genconids}
                      constraints={filteredconstraints}
                      // columns={columns}
                    />
                  </Box>
                </CustomTabPanel>
                {/* TREE VIEW TABLE */}
                {/* <CustomTabPanel value={value} index={1}>
                  <SectionHeader
                    title="Tree Dropdown View"
                    subtitle={`Select Constraint Set and specific Constraint to View More Details for NOS Impact on ${duid}`}
                  />
                  <ConstraintTree
                    genconsetidSelection={genconids}
                    constraints={filteredconstraints}
                    // columns={columns}
                  />
                </CustomTabPanel> */}

                <CustomTabPanel value={value} index={1}>
                  {/* TABLE VIEW */}
                  <SectionHeader
                    title="Table View"
                    subtitle={`Complete List of Constraint Sets & Ids that are likely to impact ${duid}`}
                  />

                  <AssetConstraintTable constraints={filteredconstraints} />
                </CustomTabPanel>
              </Box>
            </Box>
          );
        } else if (refresh === true) {
          return <Refresh refresh={refresh} />;
        } else {
          return (
            <Typography variant="h3" color={colors.grey[100]}>
              No constraints found in Network Outage Schedule (NOS) that are
              linked to {duid}.
            </Typography>
          );
        }
      })()}
    </Box>
  );
};

export default Dashboard;
